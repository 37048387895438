
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/CustomSlider.vue";

@Options({
  components: {
    CustomSlider,
  },
  data() {
    return {
      items: [
        {
          path: "/news/latest",
          name: "最新动态",
        },
        {
          path: "/news/media",
          name: "媒体报道",
        },
        {
          path: "/news/characterStory",
          name: "归蜜故事",
        },
        {
          path: "/news/publicWelfare",
          name: "公益贡献",
        },
      ],
    };
  },
})
export default class News extends Vue {}
